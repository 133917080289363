import {
    MouseEvent,
    MouseEventHandler,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
    FC,
    PropsWithChildren,
} from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { OK } from 'http-status-codes';

import Analytics from '@hh.ru/analytics-js';
import { ExclamationTriangleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TelegramSolidSize16, ViberSolidSize16, WhatsappSolidSize16 } from '@hh.ru/magritte-ui/service';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import {
    IconColor,
    WhatsappScaleSmallAppearanceDefault,
    WhatsappScaleSmallAppearanceOutlined,
    ViberScaleSmallAppearanceDefault,
    ViberScaleSmallAppearanceOutlined,
    TelegramScaleSmallAppearanceDefault,
    TelegramScaleSmallAppearanceOutlined,
} from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Notices from 'Modules/Notices';
import { DeepLink } from 'Utils/DeepLink';
import { getMessengerLinks } from 'Utils/DeepLinkHelper';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useNotification } from 'src/components/Notifications/Provider';
import getContactPhones from 'src/components/ResumeView/getContactPhones';
import TagWithContrastText from 'src/components/TagWithContrastText';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import type { Phone } from 'src/models/resumeCard';
// В будущем перенесем этот компонент туда же, он все равно не переиспользуется
// eslint-disable-next-line @hh.ru/rules/no-internal-modules
import ResumeTagsContainer from 'src/pages/ResumeView/employerRedesign/components/ResumeTagsContainer';

import styles from './deepLinks.less';

const TrlKeys = {
    search: 'mass_sending.usergroupedit.check',
    scope: 'vacancies.title.in',
    whatsapp: 'rb.personalsites.whatsapp',
    viber: 'rb.personalsites.viber',
    telegram: 'rb.personalsites.telegram',
    tryLink: 'rb.personalsites.deeplink.try',
    warning: 'deeplink.warning',
};

interface ResumePhoneDeepLinksProps {
    resumeId: string;
    created: number;
    resumeHash: string;
    simHash?: string;
    visited?: boolean;
    fromResumeView?: boolean;
    phone: Phone;
    markAsRead?: () => void;
    isMagritte?: boolean;
}

enum Messenger {
    Telegram = 'telegram',
    Viber = 'viber',
    Whatsapp = 'whatsapp',
}

const Features = {
    warningMessengers: 'warning_messengers',
};

const Wrapper: FC<{ isMagritte?: boolean } & PropsWithChildren> = ({ isMagritte, children }) => {
    if (isMagritte) {
        return <ResumeTagsContainer>{children}</ResumeTagsContainer>;
    }

    return <span className={styles.deepLinks}>{children}</span>;
};

const ResumePhoneDeepLinks: TranslatedComponent<ResumePhoneDeepLinksProps> = ({
    trls,
    resumeId,
    created,
    simHash,
    resumeHash,
    phone,
    visited,
    fromResumeView,
    markAsRead,
    isMagritte,
}) => {
    const dispatch = useDispatch();
    const displayType = useSelector(({ displayType }) => displayType);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const infoTipName = useSelector(({ infoTip }) => infoTip.name);
    const warningMessengers = (useSelector(({ features }) => features[Features.warningMessengers]) as string) || '';
    const showDropDown = infoTipName === 'whatsAppWarning' && fromResumeView;
    const { addNotification } = useNotification();
    const activatorRef = useRef(null);

    const resumePhone = phone.raw;
    const links = useMemo(
        () => getMessengerLinks({ phone: resumePhone || '', displayType }),
        [displayType, resumePhone]
    );
    const [messenger, setMessenger] = useState<Messenger | null>(null);
    const [isAsyncDeepLink, setAsyncDeepLink] = useState(false);
    const handleDeepLink = useCallback(
        (messengerType: Messenger) => {
            Analytics.sendHHEvent('resumeShowContacts', {
                goal: messengerType.toUpperCase(),
                resumeId,
                vacancyId,
            });
            const deepLink = new DeepLink({
                fallbackUrl: links[messengerType].shallow,
                androidUrl: links[messengerType].android,
                iosUrl: links[messengerType].ios,
            });
            deepLink.open(links[messengerType].deep);
        },
        [links, resumeId, vacancyId]
    );
    const handlePhoneCheck = useCallback(
        (messengerType: Messenger, event: MouseEvent) => {
            markAsRead?.();

            if (resumePhone) {
                handleDeepLink(messengerType);
                return;
            }

            void getContactPhones(
                { created, resumeHash, simHash, event, goal: `Contacts_${messengerType}` },
                addNotification
            )(dispatch).then(({ status }) => {
                if (status === OK) {
                    setMessenger(messengerType);
                    setAsyncDeepLink(true);
                }
            });
        },
        [markAsRead, resumePhone, created, resumeHash, simHash, addNotification, dispatch, handleDeepLink]
    );

    const handleViberClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Viber, event),
        [handlePhoneCheck]
    );
    const handleTelegramClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Telegram, event),
        [handlePhoneCheck]
    );
    const handleWhatsappClick: MouseEventHandler = useCallback(
        (event) => handlePhoneCheck(Messenger.Whatsapp, event),
        [handlePhoneCheck]
    );

    useEffect(() => {
        const handleClearLinkData = () => {
            setMessenger(null);
        };
        window.addEventListener('blur', handleClearLinkData);

        return () => window.removeEventListener('blur', handleClearLinkData);
    }, []);

    useEffect(() => {
        if (resumePhone && isAsyncDeepLink && messenger) {
            handleDeepLink(messenger);
            setAsyncDeepLink(false);
        }
    }, [isAsyncDeepLink, resumePhone, messenger, handleDeepLink]);

    const deepLinks = [
        {
            name: Messenger.Whatsapp,
            icon: {
                default: WhatsappScaleSmallAppearanceDefault,
                outlined: WhatsappScaleSmallAppearanceOutlined,
                margitte: WhatsappSolidSize16,
            },
            handler: handleWhatsappClick,
        },
        {
            name: Messenger.Viber,
            icon: {
                default: ViberScaleSmallAppearanceDefault,
                outlined: ViberScaleSmallAppearanceOutlined,
                margitte: ViberSolidSize16,
            },
            handler: handleViberClick,
        },
        {
            name: Messenger.Telegram,
            icon: {
                default: TelegramScaleSmallAppearanceDefault,
                outlined: TelegramScaleSmallAppearanceOutlined,
                margitte: TelegramSolidSize16,
            },
            handler: handleTelegramClick,
        },
    ] as const;

    const initalColor = visited ? IconColor.Gray50 : IconColor.Gray80;

    return (
        <Wrapper isMagritte={isMagritte}>
            {deepLinks.map(({ name, icon, handler }) => {
                const LinkIcon = icon[name === Messenger.Telegram ? 'default' : 'outlined'];
                return (
                    <HoverTip
                        key={name}
                        placement={TipPlacement.Bottom}
                        host={process.env.SSR ? null : document.body}
                        render={() =>
                            warningMessengers.includes(name)
                                ? trls[TrlKeys.warning]
                                : format(trls[TrlKeys.tryLink], {
                                      '{0}': trls[TrlKeys[name]],
                                  })
                        }
                        show={name === Messenger.Whatsapp && showDropDown}
                        onHover={() => {
                            if (name === Messenger.Whatsapp && showDropDown) {
                                infoTipName && Notices.markAsViewed(infoTipName);
                            }
                        }}
                        activatorRef={activatorRef}
                    >
                        {isMagritte ? (
                            <div ref={activatorRef} onClick={handler} className={styles.tag}>
                                <TagWithContrastText icon={icon.margitte}>{trls[TrlKeys[name]]}</TagWithContrastText>
                            </div>
                        ) : (
                            <span
                                className={classnames(styles.deepLink, {
                                    [styles.deepLinkTelegram]: name === Messenger.Telegram,
                                    [styles.deepLinkViber]: name === Messenger.Viber,
                                    [styles.deepLinkWhatsapp]: name === Messenger.Whatsapp,
                                    [`resume-contact-${name}`]: true,
                                })}
                                onClick={handler}
                                ref={activatorRef}
                            >
                                <LinkIcon initial={initalColor} highlighted={IconColor.Gray60} />
                                {warningMessengers.includes(name) && (
                                    <BlokoIconReplaceContainer>
                                        <ExclamationTriangleOutlinedSize16 initial="negative" />
                                    </BlokoIconReplaceContainer>
                                )}
                                <FormSpacer>{trls[TrlKeys[name]]}</FormSpacer>
                            </span>
                        )}
                    </HoverTip>
                );
            })}
        </Wrapper>
    );
};

export default memo(translation(ResumePhoneDeepLinks));
