import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { GridColumn, GridLayout, GridRow, VSpacing } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import { AsyncHighlighterContext, getAsyncHighlighter } from 'src/components/Markup/AsyncHighlighterModule';
import { useSelector } from 'src/hooks/useSelector';
import ResumeErrorWrapper from 'src/pages/ResumeView/components/ResumeErrorWrapper';
import ResumeEmployerTransitions from 'src/pages/ResumeView/components/ResumeTransitions';
import ResumeWithoutContactInfo from 'src/pages/ResumeView/components/ResumeWithoutContactInfo';

import EmployerResumeViewBreadcrumbs from 'src/pages/ResumeView/employerRedesign/EmployerResumeViewBreadcrumbs';
import ResumeBody from 'src/pages/ResumeView/employerRedesign/components/ResumeBody';
import ResumeButtons from 'src/pages/ResumeView/employerRedesign/components/ResumeButtons';
import ResumeHasHiddenFieldsWarning from 'src/pages/ResumeView/employerRedesign/components/ResumeHasHiddenFieldsWarning';
import ResumeSidebar from 'src/pages/ResumeView/employerRedesign/components/ResumeSidebar';

const TrlKeys = {
    notExist: 'resume.employer.notexists',
};

const EmployerResumeView: TranslatedComponent = ({ trls }) => {
    const searchQuery = useSelector((state) => state.searchQuery || '');
    const error = useSelector((state) => state.resume?.error);
    const title = useSelector((state) => state.resume?.title?.value || '');
    const { isMobile } = useBreakpoint();

    return (
        <MagritteWrapper isEnabled>
            <PageLayout
                title={error ? trls[TrlKeys.notExist] : title}
                layout={EmptyLayout}
                errorWrapper={ResumeErrorWrapper}
            >
                <GridLayout>
                    <GridRow>
                        <GridColumn xs={4} s={8} m={12} l={12} xl={12} xxl={12}>
                            <EmployerResumeViewBreadcrumbs />
                        </GridColumn>
                        <VSpacing default={24} xs={8} />
                    </GridRow>
                    <GridRow>
                        <AsyncHighlighterContext.Provider value={getAsyncHighlighter(searchQuery)}>
                            <GridColumn xs={4} s={8} m={8} l={8} xl={8} xxl={8}>
                                {!isMobile && <ResumeEmployerTransitions />}
                                {isMobile && <ResumeButtons />}
                                <ResumeWithoutContactInfo />
                                <ResumeHasHiddenFieldsWarning />
                                <ResumeBody />
                            </GridColumn>
                            <GridColumn xs={0} s={0} m={4} l={4} xl={4} xxl={4}>
                                <ResumeSidebar />
                            </GridColumn>
                        </AsyncHighlighterContext.Provider>
                    </GridRow>
                </GridLayout>
            </PageLayout>
        </MagritteWrapper>
    );
};

export default translation(EmployerResumeView);
