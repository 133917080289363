import { ComponentType, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import {
    GridRow,
    VSpacing,
    GridLayout,
    GridColumn,
    Breadcrumbs,
    useBreakpoint,
    BreadcrumbsItem,
    VSpacingContainer,
    HSpacingContainer,
} from '@hh.ru/magritte-ui';
import { format } from 'bloko/common/trl';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import { ResumeDeleteModal } from 'src/components/ResumeDeleteModal';
import { DeleteModalProvider } from 'src/components/ResumeDeleteModal/DeleteModalProvider';
import translation from 'src/components/translation';
import useIsBackoffice from 'src/hooks/useIsBackoffice';
import { selectApplicantResumeTitle } from 'src/models/applicant/resume/blocks/position/selectors';
import { useOpenEditorByUrl } from 'src/models/applicant/resume/editor/openByUrl';
import ResumeErrorWrapper from 'src/pages/ResumeView/components/ResumeErrorWrapper';

import MobileNavigationBar from 'src/pages/ResumeView/redesign/MobileNavigationBar';
import ModerationControls from 'src/pages/ResumeView/redesign/backoffice/ModerationControls';
import ResumeHistory from 'src/pages/ResumeView/redesign/backoffice/ResumeHistory';
import ApplicantResumeAbout from 'src/pages/ResumeView/redesign/blocks/about/Card';
import ApplicantResumeAdditional from 'src/pages/ResumeView/redesign/blocks/additional/Card';
import ApplicantResumeAdditionalEducation from 'src/pages/ResumeView/redesign/blocks/additionalEducation/Card';
import ApplicantRsvVerification from 'src/pages/ResumeView/redesign/blocks/applicantRsvVerification';
import ApplicantResumeAttestationEducation from 'src/pages/ResumeView/redesign/blocks/attestationEducation/Card';
import ApplicantResumeCertificates from 'src/pages/ResumeView/redesign/blocks/certificates/Card';
import ApplicantResumeContacts from 'src/pages/ResumeView/redesign/blocks/contacts/Card';
import ApplicantResumeCreds from 'src/pages/ResumeView/redesign/blocks/creds/Card';
import ApplicantResumeExperience from 'src/pages/ResumeView/redesign/blocks/experience/Card';
import LanguageSelector from 'src/pages/ResumeView/redesign/blocks/languages/LanguageSelector';
import ModerationBanner from 'src/pages/ResumeView/redesign/blocks/moderation';
import ApplicantResumePortfolio from 'src/pages/ResumeView/redesign/blocks/portfolio/Card';
import ApplicantResumePosition from 'src/pages/ResumeView/redesign/blocks/position/Card';
import ApplicantResumeRecommendation from 'src/pages/ResumeView/redesign/blocks/recommendation/Card';
import ApplicantResumeCareerPlatformCard from 'src/pages/ResumeView/redesign/blocks/skills/CareerPlatformCard';
import ApplicantResumeSkillsVerificationCard from 'src/pages/ResumeView/redesign/blocks/skills/VerificationCard';
import EditorCloseConfirm from 'src/pages/ResumeView/redesign/common/Confirm/EditorCloseConfirm';
import EditorDeleteConfirm from 'src/pages/ResumeView/redesign/common/Confirm/EditorDeleteConfirm';
import Notifications from 'src/pages/ResumeView/redesign/common/Notifications';
import ResumeCompletenessCard from 'src/pages/ResumeView/redesign/completeness';
import ResumeButtonDelete from 'src/pages/ResumeView/redesign/delete';
import ButtonDownloadDrop from 'src/pages/ResumeView/redesign/download/DownloadDrop';
import ResumeButtonPrint from 'src/pages/ResumeView/redesign/print';
import RenewalResume from 'src/pages/ResumeView/redesign/renewal';
import SuitableVacancy from 'src/pages/ResumeView/redesign/suitableVacancy';
import ResumeVisibility from 'src/pages/ResumeView/redesign/visibility/Card';

const TrlKeys = {
    newResume: 'rb.view.newresume',
    pageTitle: 'applicant.resume.page.title',
    breadcrumbs: {
        resumes: 'resumeList.title',
    },
};
const RESUMES_PAGE_URL = '/applicant/resumes';

const ResumeView: ComponentType = translation(({ trls }) => {
    useOpenEditorByUrl();
    const { isMobile } = useBreakpoint();
    const isBackoffice = useIsBackoffice();

    const title = useSelector(selectApplicantResumeTitle);

    const trlTitle = useMemo(() => {
        if (title !== '') {
            return format(trls[TrlKeys.pageTitle], { '{0}': title });
        }

        return trls[TrlKeys.newResume];
    }, [title, trls]);

    return (
        <PageLayout title={trlTitle} layout={EmptyLayout} errorWrapper={ResumeErrorWrapper}>
            <DeleteModalProvider>
                {isMobile && <MobileNavigationBar />}
                <GridLayout>
                    <GridRow>
                        <GridColumn xs={4} s={8} m={7} l={7} xl={7} xxl={7}>
                            {!isMobile && (
                                <>
                                    <Breadcrumbs>
                                        <BreadcrumbsItem href={RESUMES_PAGE_URL}>
                                            {trls[TrlKeys.breadcrumbs.resumes]}
                                        </BreadcrumbsItem>
                                        <BreadcrumbsItem>{trlTitle}</BreadcrumbsItem>
                                    </Breadcrumbs>
                                    <VSpacing default={40} />
                                </>
                            )}

                            <VSpacingContainer default={24}>
                                {isBackoffice && <ModerationControls />}
                                {!isMobile && <ModerationBanner />}
                                <ApplicantResumePosition />
                                {isMobile && (
                                    <>
                                        <ModerationBanner />
                                        <ResumeCompletenessCard />
                                        <VSpacingContainer default={16}>
                                            <RenewalResume />
                                            <ResumeVisibility />
                                            {isBackoffice && <ResumeHistory />}
                                            <SuitableVacancy />
                                        </VSpacingContainer>
                                    </>
                                )}
                                <ApplicantResumeContacts />
                                <ApplicantResumeSkillsVerificationCard />
                                <ApplicantResumeCareerPlatformCard />
                                <ApplicantResumeAbout />
                                <ApplicantResumeRecommendation />
                                <ApplicantResumeAdditionalEducation />
                                <ApplicantResumeAttestationEducation />
                                <ApplicantResumeCertificates />
                                <ApplicantResumeAdditional />
                                <ApplicantResumeCreds />
                                <ApplicantResumeExperience />
                                <ApplicantResumePortfolio />
                                <ApplicantRsvVerification />
                                <VSpacing default={32} />
                            </VSpacingContainer>
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={0} l={1} xl={1} xxl={1} />
                        <GridColumn xs={0} s={0} m={5} l={4} xl={4} xxl={4}>
                            <VSpacing default={44} />
                            <VSpacing default={40} />
                            <VSpacingContainer default={24}>
                                <HSpacingContainer default={12}>
                                    <LanguageSelector />
                                    <HSpacingContainer default={12}>
                                        <ButtonDownloadDrop />
                                        <ResumeButtonPrint />
                                        <ResumeButtonDelete />
                                    </HSpacingContainer>
                                </HSpacingContainer>
                                <ResumeCompletenessCard />
                                <VSpacingContainer default={16}>
                                    <RenewalResume />
                                    <ResumeVisibility />
                                    <SuitableVacancy />
                                    {isBackoffice && <ResumeHistory />}
                                </VSpacingContainer>
                            </VSpacingContainer>
                        </GridColumn>
                    </GridRow>
                </GridLayout>

                <EditorCloseConfirm />
                <EditorDeleteConfirm />
                <ResumeDeleteModal />
                <Notifications />
            </DeleteModalProvider>
        </PageLayout>
    );
});

export default ResumeView;
