import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import resumeHistoryButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_history_button_click';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, VSpacing, Button, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';

import { fetchResumeHistory } from 'src/models/applicant/resume/api/backoffice';
import { showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { selectNegotiationsHistory } from 'src/models/applicant/resume/negotiationsHistory/selectors';
import { selectResumeHash, selectResumeId, selectVacancyId } from 'src/models/applicant/resume/selectors';

import ResumeHistoryItem from 'src/pages/ResumeView/redesign/backoffice/ResumeHistory/ResumeHistoryItem';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.employer.history.title',
    expand: 'resume.employer.history.expand',
};

const MAX_NUMBER_OF_ITEMS_TO_RENDER = 3;
const load = makeSetStoreField('negotiationsHistory');

const ResumeHistory = translation(({ trls }) => {
    const [showAll, setShowAll] = useState(false);

    const dispatch = useDispatch();

    const vacancyId = useSelector(selectVacancyId);
    const resumeId = useSelector(selectResumeId);
    const resumeHash = useSelector(selectResumeHash);
    const negotiationsHistory = useSelector(selectNegotiationsHistory);

    const fetchHistoryAndHideButton = () => {
        resumeHistoryButtonClick({ vacancyId, resumeId });

        fetchResumeHistory({ resumeHash, vacancyId })
            .then((result) => {
                dispatch(load(result.vacancies));
                setShowAll(true);
            })
            .catch(() => {
                dispatch(showNotification('error-api'));
            });
    };

    const negotiationsHistoryList = useMemo(
        () => (showAll ? negotiationsHistory : negotiationsHistory?.slice(0, MAX_NUMBER_OF_ITEMS_TO_RENDER)),
        [negotiationsHistory, showAll]
    );

    if (!negotiationsHistoryList?.length) {
        return null;
    }

    return (
        <Card stretched showBorder padding={12} borderRadius={24} data-qa="resume-history-sidebar-container">
            <div className={styles.innerWrapper}>
                <VSpacingContainer default={24}>
                    <Text typography="title-5-semibold">{trls[TrlKeys.title]}</Text>

                    {negotiationsHistoryList.map((vacancy) => (
                        <ResumeHistoryItem vacancy={vacancy} key={vacancy['@vacancyId']} />
                    ))}
                </VSpacingContainer>
            </div>
            {!showAll && (
                <>
                    <VSpacing default={12} />
                    <Button
                        stretched
                        mode="secondary"
                        style="neutral"
                        size="medium"
                        data-qa="resume-history-toggle"
                        onClick={fetchHistoryAndHideButton}
                    >
                        {trls[TrlKeys.expand]}
                    </Button>
                </>
            )}
        </Card>
    );
});

export default ResumeHistory;
