import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

import ResumeExperienceIndustries from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperienceIndustries';
import ResumeExperienceInfo from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperienceInfo';
import ResumeExperiencePosition from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperiencePosition';

const TrlKeys = {
    title: 'rb.title.experience',
};

const ResumeExperience: TranslatedComponent = ({ trls }) => {
    const experience = useSelector((state) => state.resume?.experience.value);
    const totalExperience = useSelector((state) => state.resume?.totalExperience);

    if (!experience?.length) {
        return null;
    }

    return (
        <ResumeBlock
            title={
                <>
                    {`${trls[TrlKeys.title]}: `}
                    <ConversionDateInterval years={totalExperience?.years || 0} months={totalExperience?.months || 0} />
                </>
            }
        >
            <VSpacingContainer default={40}>
                {experience.map(
                    ({
                        id,
                        employerId,
                        companyName,
                        companyState,
                        companyAreaId,
                        companyAreaTitle,
                        companyUrl,
                        prettyUrl,
                        interval,
                        startDate,
                        endDate,
                        companyLogos,
                        industries,
                        position,
                        description,
                    }) => (
                        <div key={id}>
                            <ResumeExperienceInfo
                                employerId={employerId}
                                companyName={companyName}
                                companyState={companyState}
                                companyAreaId={companyAreaId}
                                companyAreaTitle={companyAreaTitle}
                                companyUrl={companyUrl}
                                prettyUrl={prettyUrl}
                                interval={interval}
                                startDate={startDate}
                                endDate={endDate}
                                companyLogos={companyLogos}
                            />
                            <ResumeExperienceIndustries industries={industries} />
                            <ResumeExperiencePosition position={position} description={description} />
                        </div>
                    )
                )}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeExperience);
