import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, Button, VSpacingContainer, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { selectResumeHash, selectResumeStatus } from 'src/models/applicant/resume/selectors';
import { CORRECTED, BLOCKED } from 'src/utils/constants/resumeStatuses';

import styles from './styles.less';

const TrlKeys = {
    blocked: {
        title: 'resume.moderation.blocked.title',
        description: 'resume.moderation.blocked.description',
        button: 'resume.moderation.blocked.button',
    },
    corrected: {
        title: 'resume.moderation.corrected.title',
        description: 'resume.moderation.corrected.description',
    },
};

const ModerationBanner = translation(({ trls }) => {
    const status = useSelector(selectResumeStatus);
    const hash = useSelector(selectResumeHash);

    const { isMobile } = useBreakpoint();

    if (status === BLOCKED) {
        return (
            <Card stretched padding={isMobile ? 12 : 24} borderRadius={24} style="negative">
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <VSpacingContainer default={6}>
                            <Text typography="custom-1-semibold">{trls[TrlKeys.blocked.title]}</Text>
                            <Text typography="label-2-regular">{trls[TrlKeys.blocked.description]}</Text>
                        </VSpacingContainer>
                    </div>
                    <Button
                        Element={Link}
                        to={`/applicant/resumes/completion?resume=${hash}`}
                        size="small"
                        mode="primary"
                        style="negative"
                    >
                        {trls[TrlKeys.blocked.button]}
                    </Button>
                </div>
            </Card>
        );
    }

    if (status === CORRECTED) {
        return (
            <Card stretched padding={24} borderRadius={24} style="neutral">
                <VSpacingContainer default={6}>
                    <Text typography="custom-1-semibold">{trls[TrlKeys.corrected.title]}</Text>
                    <Text typography="label-2-regular">{trls[TrlKeys.corrected.description]}</Text>
                </VSpacingContainer>
            </Card>
        );
    }

    return null;
});

export default ModerationBanner;
