import {
    VSpacing,
    Button,
    Modal,
    Text,
    ActionBar,
    Card,
    VSpacingContainer,
    useBreakpoint,
    BottomSheet,
    NavigationBar,
    Action,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { DOT } from 'src/utils/constants/symbols';

const TrlKeys = {
    title: 'skillLevels.modal.title',
    levels: {
        basic: {
            rank: 1,
            title: 'skillLevels.modal.basic',
            items: {
                0: 'skillLevels.modal.basic.0',
                1: 'skillLevels.modal.basic.1',
            },
        },
        medium: {
            rank: 2,
            title: 'skillLevels.modal.medium',
            items: {
                0: 'skillLevels.modal.medium.0',
                1: 'skillLevels.modal.medium.1',
            },
        },
        advanced: {
            rank: 3,
            title: 'skillLevels.modal.advanced',
            items: {
                0: 'skillLevels.modal.advanced.0',
                1: 'skillLevels.modal.advanced.1',
                2: 'skillLevels.modal.advanced.2',
            },
        },
    },
    noneTitle: 'skillLevels.modal.none',
    close: 'skillLevels.modal.close',
};

interface ResumeSkillsModalProps {
    visible: boolean;
    onClose: () => void;
}

const ResumeSkillsModal: TranslatedComponent<ResumeSkillsModalProps> = ({ trls, visible, onClose }) => {
    const { isMobile } = useBreakpoint();

    const levels = Object.keys(TrlKeys.levels);
    const content = (
        <VSpacingContainer default={16}>
            {levels.map((level) => {
                const items = Object.values(TrlKeys.levels[level].items);

                return (
                    <Card key={level} padding={24} showBorder borderRadius={24}>
                        <Text typography="title-5-semibold">{trls[TrlKeys.levels[level].title]}</Text>
                        <VSpacing default={8} />
                        <ul>
                            {items.map((item) => (
                                <li key={level + item}>
                                    <Text typography="label-2-regular">{`${DOT} ${trls[item]}`}</Text>
                                </li>
                            ))}
                        </ul>
                    </Card>
                );
            })}
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.noneTitle]}
            </Text>
        </VSpacingContainer>
    );
    const closeAction = <Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />;
    const closeButton = (
        <Button mode="secondary" onClick={onClose}>
            {trls[TrlKeys.close]}
        </Button>
    );

    return isMobile ? (
        <BottomSheet
            visible={visible}
            onClose={onClose}
            header={<NavigationBar size="large" right={closeAction} title={trls[TrlKeys.title]} />}
        >
            {content}
            <VSpacing default={8} />
        </BottomSheet>
    ) : (
        <Modal
            visible={visible}
            onClose={onClose}
            size="large"
            titleSize="large"
            title={trls[TrlKeys.title]}
            actions={closeAction}
            footer={<ActionBar type="modal" primaryActions={closeButton} />}
        >
            {content}
        </Modal>
    );
};

export default translation(ResumeSkillsModal);
