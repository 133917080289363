import { PropsWithChildren, ReactElement, ReactNode } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, ActionBar, BottomSheetFooter, NavigationBarSize, Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import {
    selectResumeEditorLoading,
    selectResumeEditorSaving,
} from 'src/models/applicant/resume/editor/store/selectors';

import SmartModal from 'src/pages/ResumeView/redesign/common/EditorModal/SmartModal';

export interface EditorModalProps {
    title?: string;
    onSave: () => void;
    onClose: () => void;
    visible: boolean;
    onDelete?: () => void;
    onAfterExit?: () => void;
    fullScreen?: boolean;
    disableSave?: boolean;
    description?: string;
    extraSecondaryAction?: ReactNode;
    modalProps?: {
        footer?: ReactNode;
        actions?: ReactNode;
        options?: ReactNode;
    };
    bottomSheetProps?: {
        size?: NavigationBarSize;
        left?: ReactElement;
        right?: ReactElement;
        footer?: ReactElement;
        showDivider?: false | 'with-scroll';
        interceptClickHandlers?: boolean;
    };
}
const TrlKeys = {
    save: 'resume.editor.save',
    cancel: 'resume.editor.cancel',
    delete: 'resume.editor.delete',
};

const EditorModal = translation<PropsWithChildren<EditorModalProps>>(
    ({
        children,
        trls,
        title,
        visible,
        onClose,
        onSave,
        onDelete,
        fullScreen,
        description,
        onAfterExit,
        disableSave = false,
        extraSecondaryAction,
        modalProps = {},
        bottomSheetProps = {},
    }) => {
        const saving = useSelector(selectResumeEditorSaving);
        const loading = useSelector(selectResumeEditorLoading);
        const loadingBtns = saving || loading;

        return (
            <SmartModal
                title={title}
                visible={visible}
                onClose={onClose}
                loading={loading}
                fullScreen={fullScreen}
                onAfterExit={onAfterExit}
                description={description}
                bottomSheetProps={{
                    ...bottomSheetProps,
                    size: bottomSheetProps.size || 'large',
                    right: bottomSheetProps.right || <Action icon={CrossOutlinedSize24} onClick={onClose} />,
                    showDivider: bottomSheetProps.showDivider || 'with-scroll',
                    footer: bottomSheetProps.footer || (
                        <BottomSheetFooter>
                            <Button
                                disabled={disableSave}
                                mode="primary"
                                style="accent"
                                size="medium"
                                onClick={onSave}
                                loading={loadingBtns}
                                stretched
                                data-qa="resume-modal-button-save"
                            >
                                {trls[TrlKeys.save]}
                            </Button>
                            {onDelete ? (
                                <Button
                                    mode="secondary"
                                    style="negative"
                                    size="medium"
                                    onClick={onDelete}
                                    stretched={false}
                                    loading={loadingBtns}
                                    data-qa="profile-modal-button-delete"
                                >
                                    {trls[TrlKeys.delete]}
                                </Button>
                            ) : undefined}
                        </BottomSheetFooter>
                    ),
                }}
                modalProps={{
                    actions: modalProps.actions || (
                        <Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={onClose} />
                    ),
                    footer: modalProps.footer || (
                        <ActionBar
                            primaryActions={
                                <Button
                                    mode="primary"
                                    style="accent"
                                    size="medium"
                                    stretched={false}
                                    onClick={onSave}
                                    loading={loadingBtns}
                                    disabled={disableSave}
                                >
                                    {trls[TrlKeys.save]}
                                </Button>
                            }
                            secondaryActions={
                                <>
                                    {extraSecondaryAction}
                                    {onDelete ? (
                                        <Button
                                            mode="secondary"
                                            style="negative"
                                            size="medium"
                                            onClick={onDelete}
                                            stretched={false}
                                            loading={loadingBtns}
                                        >
                                            {trls[TrlKeys.delete]}
                                        </Button>
                                    ) : undefined}
                                </>
                            }
                        />
                    ),
                }}
            >
                {children}
            </SmartModal>
        );
    }
);

export default EditorModal;
