import { useMemo } from 'react';

import { Text, Trigger, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { Industry } from 'src/models/resume/resumeCommon.types';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

import styles from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperienceIndustries/styles.less';

const TrlKeys = {
    expand: 'company.employer.industries.open.new',
};

const ResumeExperienceIndustries: TranslatedComponent<{ industries: Industry[] }> = ({ industries, trls }) => {
    const [expanded, setOn] = useOnOffState(false);

    const filteredIndustries = useMemo(() => industries.filter((industry) => industry.children.length), [industries]);

    if (!filteredIndustries?.length) {
        return null;
    }

    if (!expanded) {
        const industry = filteredIndustries[0];
        return (
            <>
                <VSpacing default={16} />
                <div className={styles.collapsed}>
                    <Text typography="label-3-regular">{industry.title}</Text>
                    <Trigger
                        size="xsmall"
                        onChange={setOn}
                        label={trls[TrlKeys.expand]}
                        expanded={expanded}
                        values={[]}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <VSpacing default={16} />
            <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                {filteredIndustries.map((industry) => (
                    <div key={industry.id}>
                        <Text typography="label-3-regular">{industry.title}</Text>
                        <ul>
                            {industry.children.map((subIndustry) => (
                                <li key={subIndustry.id}>
                                    <ResumeBullet typography="paragraph-3-regular" leftSpace={false} paddings={false} />
                                    <Text Element="span" typography="paragraph-3-regular">
                                        {subIndustry.title}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </VSpacingContainer>
        </>
    );
};

export default translation(ResumeExperienceIndustries);
