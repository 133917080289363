import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import chatEntryResumeContactsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_click';
import chatEntryResumeContactsShow from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_show';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { Button as MagritteButton } from '@hh.ru/magritte-ui';
import { BubbleRoundOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import urlParser from 'bloko/common/urlParser';

import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const ResumeContactsChatButton = ({ isResumeViewMagritte = false, onContactsClick, trls }) => {
    const buttonRef = useRef(null);
    const openChatik = useContext(ChatikContext)?.openChatik;
    const chatikIntegration = useSelector((state) => state.chatikIntegration);
    const location = useSelector((state) => state.router.location);
    const vacancyId = urlParser(location.search)?.params?.vacancyId?.[0];
    const resumeId = urlParser(location.search)?.params?.resumeId?.[0];
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const canUseChat = useSelector(selectorCanCommunicateWithApplicant);

    const chatIdFromTopic =
        topicList && topicList.length
            ? topicList?.find((item) => item.vacancyId.toString() === vacancyId)?.chatId
            : null;

    // tempexp_PORTFOLIO-36442_start
    const resumeHash = useSelector(({ resume }) => resume?.hash);

    const [commonChatId, setCommonChatId] = useState(null);
    const [commonChatIdLoading, setCommonChatIdLoading] = useState(false);

    const isChatWithoutVacancyExp = useExperiment(
        'chat_without_vac_with_webcall',
        canUseChat && chatikIntegration && Boolean(resumeHash) && !chatIdFromTopic
    );
    // tempexp_PORTFOLIO-36442_end

    useEffect(() => {
        if (buttonRef.current !== null) {
            const { stopSpying } = chatEntryResumeContactsShow(buttonRef.current, { vacancyId, resumeId });

            return () => {
                stopSpying();
            };
        }
        return undefined;
    }, [buttonRef, resumeId, vacancyId]);

    if (!isChatWithoutVacancyExp && (!canUseChat || !chatIdFromTopic || !chatikIntegration)) {
        return null;
    }

    const handleChatFromTopicOpen = () => {
        openChatik?.({
            chatId: chatIdFromTopic,
            view: 'compact',
            hhtmFromLabel: 'resume',
        });
        onContactsClick?.();
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });
    };

    // tempexp_PORTFOLIO-36442_start
    const handleCommonChatOpen = () => {
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });

        if (commonChatId) {
            openChatik?.({
                chatId: commonChatId,
                view: 'compact',
            });

            return;
        }

        setCommonChatIdLoading(true);

        fetcher
            .put('/shards/resume/chat_without_vacancy', { resume: resumeHash ?? '' })
            .then((data) => {
                setCommonChatId(data.data.chat_id);
                openChatik({ chatId: data.data.chat_id });
            })
            .finally(() => setCommonChatIdLoading(false));
    };

    const magritteButton = (
        <MagritteButton
            ref={buttonRef}
            onClick={isChatWithoutVacancyExp ? handleCommonChatOpen : handleChatFromTopicOpen}
            mode="secondary"
            size="small"
            style="neutral"
            icon={isResumeViewMagritte ? <BubbleRoundOutlinedSize16 /> : undefined}
            loading={commonChatIdLoading}
        >
            {trls[ResumeContactsChatButton.trls.text]}
        </MagritteButton>
    );

    return isResumeViewMagritte ? (
        <ResumeBlockButtonWrapper>{magritteButton}</ResumeBlockButtonWrapper>
    ) : (
        magritteButton
    );
};

ResumeContactsChatButton.trls = {
    text: 'chatik.activator.resume',
};

ResumeContactsChatButton.propTypes = {
    onContactsClick: PropTypes.func,
    trls: PropTypes.object,
};

export default translation(ResumeContactsChatButton);
