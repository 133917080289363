import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import {
    Cell,
    Text,
    Title,
    Button,
    VSpacing,
    Checkbox,
    ActionBar,
    CheckableCard,
    VSpacingContainer,
    BottomSheetFooter,
    useBreakpoint,
} from '@hh.ru/magritte-ui';

import CdnImg from 'src/components/CdnImg';
import { ArtifactDTO } from 'src/models/applicant/resume/api/artifacts/dto';
import { selectApplicantResumeArtivacts } from 'src/models/applicant/resume/artifacts/selectors';
import {
    selectResumeEditorPortfolio,
    selectResumeEditorOpenedPortfolio,
} from 'src/models/applicant/resume/blocks/portfolio/edit/selector';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import {
    selectResumeEditorLoading,
    selectResumeEditorSaving,
} from 'src/models/applicant/resume/editor/store/selectors';
import { setResumeEditorFieldValue } from 'src/models/applicant/resume/editor/store/slice';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import styles from './styles.less';

const TrlKeys = {
    save: 'resume.editor.save',
    title: 'resume.editor.portfolio.title',
    empty: 'applicant.resume.portfolio.empty',
    setting: 'resume.editor.portfolio.setting.link',
    emptyArtifacts: {
        link: 'resume.editor.portfolio.empty.link',
        title: 'resume.editor.portfolio.empty.title',
        description: 'resume.editor.portfolio.empty.description',
    },
};

export const ResumePortfolioEditor = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const saving = useSelector(selectResumeEditorSaving);
    const loading = useSelector(selectResumeEditorLoading);
    const loadingBtns = saving || loading;

    const visible = useSelector(selectResumeEditorOpenedPortfolio);
    const artifacts = useSelector(selectApplicantResumeArtivacts);
    const portfolioIds = useSelector(selectResumeEditorPortfolio);
    const isArtifactsEmpty = artifacts.length === 0 && !loading;

    const selectedIdsMap = useMemo(() => {
        return portfolioIds.reduce((acc, id) => {
            acc.set(id, true);
            return acc;
        }, new Map());
    }, [portfolioIds]);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());

    const handleSelect = ({ id }: ArtifactDTO) => {
        const portfolio = selectedIdsMap.has(id)
            ? portfolioIds.filter((portfolioId) => portfolioId !== id)
            : [...portfolioIds, id];

        dispatch(
            setResumeEditorFieldValue({
                portfolio,
            })
        );
    };

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            bottomSheetProps={{
                footer: (
                    <BottomSheetFooter>
                        {isArtifactsEmpty ? (
                            <Button
                                Element="a"
                                href="/applicant/gallery"
                                mode="primary"
                                style="accent"
                                size="medium"
                                data-qa="resume-modal-button-setting"
                            >
                                {trls[TrlKeys.emptyArtifacts.link]}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    mode="primary"
                                    style="accent"
                                    size="medium"
                                    onClick={handleSave}
                                    loading={loadingBtns}
                                    stretched
                                    data-qa="resume-modal-button-save"
                                >
                                    {trls[TrlKeys.save]}
                                </Button>

                                <Button
                                    Element="a"
                                    href="/applicant/gallery"
                                    mode="secondary"
                                    style="accent"
                                    size="medium"
                                    stretched
                                    data-qa="resume-modal-button-setting"
                                >
                                    {trls[TrlKeys.setting]}
                                </Button>
                            </>
                        )}
                    </BottomSheetFooter>
                ),
            }}
            modalProps={{
                footer: (
                    <ActionBar
                        primaryActions={
                            isArtifactsEmpty ? (
                                <Button
                                    Element="a"
                                    href="/applicant/gallery"
                                    mode="primary"
                                    style="accent"
                                    size="medium"
                                    stretched={false}
                                    data-qa="resume-modal-button-setting"
                                >
                                    {trls[TrlKeys.emptyArtifacts.link]}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        Element="a"
                                        href="/applicant/gallery"
                                        mode="secondary"
                                        style="accent"
                                        size="medium"
                                        stretched={false}
                                        data-qa="resume-modal-button-setting"
                                    >
                                        {trls[TrlKeys.setting]}
                                    </Button>
                                    <Button
                                        mode="primary"
                                        style="accent"
                                        size="medium"
                                        stretched={false}
                                        onClick={handleSave}
                                        loading={loadingBtns}
                                        data-qa="resume-modal-button-save"
                                    >
                                        {trls[TrlKeys.save]}
                                    </Button>
                                </>
                            )
                        }
                    />
                ),
            }}
        >
            {isArtifactsEmpty ? (
                <div className={styles.emptyArtifacts}>
                    <div className={styles.emptyImage} />
                    <Title
                        Element="h4"
                        size={isMobile ? 'small' : 'medium'}
                        alignment="center"
                        description={trls[TrlKeys.emptyArtifacts.description]}
                    >
                        {trls[TrlKeys.emptyArtifacts.title]}
                    </Title>
                    {!isMobile && <VSpacing default={24} />}
                </div>
            ) : (
                <VSpacingContainer default={16}>
                    {artifacts.map((artifact) => {
                        const { title, big, id } = artifact;
                        const isEmptyTitle = title == null;
                        const checked = selectedIdsMap.has(id);
                        return (
                            <CheckableCard
                                key={id}
                                type="checkbox"
                                flexible
                                checked={checked}
                                padding={16}
                                onChange={() => handleSelect(artifact)}
                                borderRadius={12}
                            >
                                <Cell
                                    left={<Checkbox checked={checked} onChange={() => handleSelect(artifact)} />}
                                    avatar={big ? <CdnImg className={styles.artifact} path={big} /> : undefined}
                                >
                                    <Text
                                        maxLines={4}
                                        style={isEmptyTitle ? 'secondary' : 'primary'}
                                        typography="label-2-regular"
                                    >
                                        {isEmptyTitle ? trls[TrlKeys.empty] : title}
                                    </Text>
                                </Cell>
                            </CheckableCard>
                        );
                    })}
                </VSpacingContainer>
            )}
            <VSpacing default={8} />
        </EditorModal>
    );
});
