import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, Cell, CellText } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, EyeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { format } from 'bloko/common/trl';

import { useTopLevelSite } from 'src/hooks/useSites';
import {
    selectApplicantResumeAccessType,
    selectApplicantResumeHiddenFields,
} from 'src/models/applicant/resume/blocks/visibility/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { VisibilityEditor } from 'src/pages/ResumeView/redesign/visibility/Editor';

const TrlKeys = {
    title: 'applicant.resume.visibility.card.title',
    anonymous: 'applicant.resume.visibility.card.anonymous',
    visibility: {
        all: 'applicant.resume.visibility.card.visibility.all',
        whitelist: 'applicant.resume.visibility.card.visibility.whitelist',
        blacklist: 'applicant.resume.visibility.card.visibility.blacklist',
        direct: 'applicant.resume.visibility.card.visibility.direct',
        no_one: 'applicant.resume.visibility.card.visibility.noOne',
    },
};

const ResumeVisibility = translation(({ trls }) => {
    const dispatch = useDispatch();
    const accessType = useSelector(selectApplicantResumeAccessType);
    const hiddenFields = useSelector(selectApplicantResumeHiddenFields);
    const site = useTopLevelSite();
    const getAccessTypeText = () => {
        if (accessType === 'clients') {
            return format(trls[TrlKeys.visibility.all], { '{0}': site });
        }
        if (accessType === 'whitelist') {
            return trls[TrlKeys.visibility.whitelist];
        }
        if (accessType === 'blacklist') {
            return trls[TrlKeys.visibility.blacklist];
        }
        if (accessType === 'direct') {
            return trls[TrlKeys.visibility.direct];
        }
        if (accessType === 'no_one') {
            return trls[TrlKeys.visibility.no_one];
        }
        return '';
    };
    const getVisibleText = () => {
        if (hiddenFields.length > 0) {
            return format(trls[TrlKeys.anonymous], { '{0}': getAccessTypeText() });
        }
        return capitalized(getAccessTypeText());
    };
    return (
        <>
            <Card
                padding={24}
                borderRadius={24}
                showBorder
                actionCard
                onClick={() => dispatch(openResumeEditorModal('visibility'))}
            >
                <Cell align="top" left={<EyeOutlinedSize24 />} right={<ChevronRightOutlinedSize24 />}>
                    <CellText type="description">{trls[TrlKeys.title]}</CellText>
                    <CellText>{getVisibleText()}</CellText>
                </Cell>
            </Card>
            <VisibilityEditor />
        </>
    );
});

export default ResumeVisibility;
