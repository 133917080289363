import { ReactElement, ReactNode, useState } from 'react';

import { Action, Button, Card, Cell, CellText, FormLabel, Link, VSpacing } from '@hh.ru/magritte-ui';
import { PenOutlinedSize24, PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.block.add',
    hide: 'applicant.resume.block.btn.hide',
    expanded: 'applicant.resume.block.btn.expanded',
};

const MAX_ITEMS = 2;

interface Item {
    id: number;
    title: ReactNode;
    subtitle: ReactNode;
    description: ReactNode;
    avatar?: ReactElement;
}

interface Props {
    label: ReactNode;
    items: Item[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    banner?: ReactNode;
}

const DesktopCard = translation<Props>(({ trls, onAdd, onEdit, items, label, banner }) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpanded = () => setExpanded(!expanded);

    const showBtn = items.length > MAX_ITEMS;
    const itemToShow = expanded ? items.length : MAX_ITEMS;

    return (
        <div>
            <div className={styles.header}>
                <FormLabel>{label}</FormLabel>

                <Link onClick={onAdd} iconLeft={<PlusOutlinedSize16 />}>
                    {trls[TrlKeys.add]}
                </Link>
            </div>
            {banner && (
                <>
                    {banner}
                    <VSpacing default={12} />
                </>
            )}
            {!isEmpty(items) && (
                <Card stretched padding={12} borderRadius={24} showBorder>
                    <div className={styles.card}>
                        {items.slice(0, itemToShow).map(({ id, title, subtitle, description, avatar }, index) => (
                            <Cell
                                key={id}
                                align="top"
                                right={
                                    <Action
                                        style="neutral"
                                        icon={PenOutlinedSize24}
                                        mode="secondary"
                                        disablePadding
                                        onClick={() => onEdit(index)}
                                        showBackground={false}
                                    />
                                }
                                avatar={avatar}
                            >
                                <CellText>{title}</CellText>
                                <CellText type="subtitle">{subtitle}</CellText>
                                <CellText type="description">{description}</CellText>
                            </Cell>
                        ))}
                    </div>
                    {showBtn && (
                        <>
                            <VSpacing default={12} />
                            <Button stretched size="medium" style="neutral" mode="secondary" onClick={handleExpanded}>
                                {expanded ? trls[TrlKeys.hide] : trls[TrlKeys.expanded]}
                            </Button>
                        </>
                    )}
                </Card>
            )}
        </div>
    );
});

export default DesktopCard;
