import { useCallback, useMemo } from 'react';

import resumeVideoLinkButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_video_link_button_click';
import resumeVideoLinkElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_video_link_element_shown';
import { Link as MagritteLink } from '@hh.ru/magritte-ui';
import { PlayOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import HSpacing from 'bloko/blocks/hSpacing';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    videoPresentation: 'resume.video.withIcon',
    videoResume: 'resume.videoResume',
};

const ResumeVideoLink: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const videoLink = useSelector((state) => state.resume?.videos?.[0]);
    const resumeId = useSelector((state) => Number(state.resume?.id));
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    const analyticsData = useMemo(() => ({ hhtmSource: hhtmSource as HhtmSource, resumeId }), [hhtmSource, resumeId]);

    const sendVideoClickAnalytic = useCallback(() => {
        resumeVideoLinkButtonClick(analyticsData);
    }, [analyticsData]);

    if (!videoLink) {
        return null;
    }

    return (
        <ElementShownAnchor fn={resumeVideoLinkElementShown} {...analyticsData}>
            {isMagritte ? (
                <MagritteLink
                    target="_blank"
                    href={videoLink}
                    style="accent"
                    typography="label-2-regular"
                    iconLeft={<PlayOutlinedSize16 />}
                    onClick={sendVideoClickAnalytic}
                >
                    {trls[TrlKeys.videoResume]}
                </MagritteLink>
            ) : (
                <Link target="_blank" to={videoLink} onClick={sendVideoClickAnalytic}>
                    <div className={styles.containerWithIcon}>
                        <div className={styles.playIcon} />
                        <HSpacing base={2} />
                        <Text Element="span" importance={TextImportance.Primary} size={TextSize.Large}>
                            {trls[TrlKeys.videoPresentation]}
                        </Text>
                    </div>
                </Link>
            )}
        </ElementShownAnchor>
    );
};

export default translation(ResumeVideoLink);
