import { ComponentType, PropsWithChildren } from 'react';

import { Cell, CellText, CheckableCard, Checkbox, Radio, useBreakpoint } from '@hh.ru/magritte-ui';

export const ItemCard: ComponentType<
    { checked: boolean; onChange: VoidFunction; checkbox?: boolean } & PropsWithChildren
> = ({ checked, onChange, children, checkbox }) => {
    const { isMobile } = useBreakpoint();
    const addon = checkbox ? <Checkbox checked={checked} readOnly /> : <Radio checked={checked} readOnly />;
    return (
        <CheckableCard
            type={checkbox ? 'checkbox' : 'radio'}
            borderRadius={12}
            padding={16}
            checked={checked}
            onChange={onChange}
            flexible
        >
            <Cell left={!isMobile ? addon : undefined} right={isMobile ? addon : undefined}>
                <CellText>{children}</CellText>
            </Cell>
        </CheckableCard>
    );
};
