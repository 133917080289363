import { FC } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';

import Markup from 'src/components/Markup';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';

import styles from './styles.less';

interface ResumeExperiencePositionProps {
    position?: string;
    description?: string;
}

const ResumeExperiencePosition: FC<ResumeExperiencePositionProps> = ({ position, description }) => {
    if (!position || !description) {
        return null;
    }

    return (
        <div>
            <VSpacing default={24} />
            <Text typography="subtitle-2-semibold" data-qa="resume-block-experience-position">
                <div className={styles.text}>
                    <AsyncHighlighter>{position}</AsyncHighlighter>
                </div>
            </Text>
            <Text typography="paragraph-3-regular" data-qa="resume-block-experience-description">
                <div className={styles.text}>
                    <Markup>{description}</Markup>
                </div>
            </Text>
        </div>
    );
};

export default ResumeExperiencePosition;
