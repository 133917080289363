import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useResumeApplicantFullName } from 'src/pages/ResumeView/employerRedesign/utils';
import { NAMES_AND_PHOTO } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.name',
    candidate: 'resume.stub.candidate',
    pfpCandidate: 'resume.stub.candidatePfp',
    pfpVacancyHint: 'resume.stub.pfpVacancyHint',
};

const ResumeName: TranslatedComponent = ({ trls }) => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields.includes(NAMES_AND_PHOTO));
    const isAllowed = useSelector((state) => state.resume?.permission !== VIEW_WITHOUT_CONTACTS);
    const isEmpty = useSelector((state) => state.resume?.emptyBlocks.includes('personal'));
    const name = useResumeApplicantFullName();
    const hasPfpTopics = useSelector((state) => state.resume?.hasPfpTopics);
    const renderPlaceholder = () => {
        return <Text typography="title-5-semibold">{trls[TrlKeys[hasPfpTopics ? 'pfpCandidate' : 'candidate']]}</Text>;
    };
    const renderName = () => {
        return (
            <Text typography="title-5-semibold" data-qa="resume-personal-name">
                <AsyncHighlighter>{name}</AsyncHighlighter>
            </Text>
        );
    };
    const renderHidden = () => {
        return <Text typography="title-5-semibold">{trls[TrlKeys.isHidden]}</Text>;
    };

    if (isEmpty || !isAllowed) {
        return renderPlaceholder();
    }
    if (isHidden) {
        return renderHidden();
    }
    return renderName();
};

export default translation(ResumeName);
