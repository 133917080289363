import { Fragment, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import translation from 'src/components/translation';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import { NOT_FINISHED, resumeStatusType } from 'src/utils/constants/resumeStatuses';

import { GREEN } from 'src/pages/ResumeView/components/View/ResumeSidebar/resumeFieldStatuses';

const EXCLUDED_FIELDS = ['photoUrls', 'portfolioUrls', 'age', 'advancedKeySkills', 'residenceDistrict'];

const ResumeProgress = ({ resume, trls }) => {
    const { percent, status, fieldStatuses } = resume;
    const { redFields, greenFields, leftToFillFields } = fieldStatuses;
    const renderedFields = useMemo(() => new Set(), []);
    const renderedBlocks = useMemo(() => new Set(), []);

    const renderLeftToFillText = useCallback(() => {
        if (redFields.length) {
            return trls[ResumeProgress.trls.required];
        }
        if (status === NOT_FINISHED) {
            return (
                <Fragment>
                    {trls[ResumeProgress.trls.readyToPublish]}
                    <br />
                    {trls[ResumeProgress.trls.optional]}
                </Fragment>
            );
        }
        return trls[ResumeProgress.trls.optional];
    }, [status, redFields, trls]);

    const getPropsForEditLink = useCallback(
        (field) => {
            if (resume[field]._attributes.status === GREEN) {
                switch (field) {
                    case 'certificates':
                        return {
                            subBlock: 'attestationEducation',
                            trlKey: `field.certificates`,
                        };
                    case 'elementaryEducation':
                        return {
                            subBlock: 'primaryEducation',
                            trlKey: `field.primaryEducation`,
                        };
                    case 'skills':
                        return {
                            subBlock: 'skills',
                            trlKey: `field.skills.personal`,
                        };
                    default:
                        return {
                            subBlock: field,
                            trlKey: `field.${field}`,
                        };
                }
            }
            if (field === 'keySkills') {
                return {
                    trlKey: `field.${field}`,
                    anchor: 'keySkills',
                };
            }
            return {
                trlKey: `block.${resume[field].block}`,
            };
        },
        [resume]
    );

    const renderLeftToFillItem = useCallback(
        (field) => {
            const block = resume[field].block;
            // Может быть несколько полей из одного блока
            // Надо сохранять уже выведенные блоки, и рендерить каждый блок только один раз
            const needRenderField = !renderedBlocks.has(block) || !redFields.includes(field);
            if (
                renderedFields.has(field) ||
                EXCLUDED_FIELDS.includes(field) ||
                (field !== 'keySkills' && !needRenderField)
            ) {
                return null;
            }

            renderedFields.add(field);
            renderedBlocks.add(block);
            const { subBlock, trlKey, anchor } = getPropsForEditLink(field);

            return (
                <li
                    key={field}
                    className="resume-progress-item"
                    data-qa={`resume-progress-item-${String(block)}${subBlock ? `-${subBlock}` : ''}`}
                >
                    <ResumeEditLink
                        block={block}
                        field={subBlock}
                        text={trls[ResumeProgress.trls[trlKey]]}
                        capitalize={false}
                        className="resume-progress-item__link"
                        anchor={anchor}
                    />
                </li>
            );
        },
        [redFields, renderedFields, renderedBlocks, trls, getPropsForEditLink, resume]
    );

    const renderLeftToFill = useCallback(() => {
        if (!leftToFillFields.length) {
            return null;
        }

        return (
            <Fragment>
                <div className="resume-sidebar-link">{renderLeftToFillText()}</div>
                <ul>
                    {redFields.map((field) => renderLeftToFillItem(field))}
                    {!redFields.length && greenFields.map((field) => renderLeftToFillItem(field))}
                </ul>
            </Fragment>
        );
    }, [leftToFillFields, redFields, greenFields, renderLeftToFillItem, renderLeftToFillText]);

    return (
        <Fragment>
            <div className="progressbar">
                <div
                    className={classnames('progressbar__progress', {
                        [`progressbar__progress_full`]: percent === 100,
                    })}
                    style={{ width: `${percent}%` }}
                >
                    {percent > 25 && `${percent}%`}
                </div>
                {percent <= 25 && <div className="progressbar__percentage">{`${percent}%`}</div>}
            </div>
            {renderLeftToFill()}
        </Fragment>
    );
};

ResumeProgress.propTypes = {
    resume: PropTypes.shape({
        percent: PropTypes.number.isRequired,
        status: resumeStatusType.isRequired,
        fieldStatuses: PropTypes.shape({
            redFields: PropTypes.array.isRequired,
            greenFields: PropTypes.array.isRequired,
            leftToFillFields: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
    trls: PropTypes.object.isRequired,
};

ResumeProgress.trls = {
    readyToPublish: 'rb.view.ready_to_publish',
    required: 'rb.progress.lefttofill.required',
    optional: 'rb.progress.lefttofill.optional',
    moderation: 'rb.progress.lefttofill.moderation',
    'block.experience': 'rb.progress.lefttofill.block.experience',
    'block.photo': 'rb.progress.lefttofill.block.photo',
    'block.position': 'rb.progress.lefttofill.block.position',
    'block.education': 'rb.progress.lefttofill.block.education',
    'block.personal': 'rb.progress.lefttofill.block.personal',
    'block.contacts': 'rb.progress.lefttofill.block.contacts',
    'block.additional': 'rb.progress.lefttofill.block.additional',
    'field.education': 'rb.progress.lefttofill.field.education',
    'field.position': 'rb.progress.lefttofill.field.position',
    'field.firstName': 'rb.progress.lefttofill.field.firstName',
    'field.professionalRole': 'rb.progress.lefttofill.field.professionalRole',
    'field.middleName': 'rb.progress.lefttofill.field.middleName',
    'field.lastName': 'rb.progress.lefttofill.field.lastName',
    'field.area': 'rb.progress.lefttofill.field.area',
    'field.relocation': 'rb.progress.lefttofill.field.relocation',
    'field.businessTripReadiness': 'rb.progress.lefttofill.field.businessTripReadiness',
    'field.gender': 'rb.progress.lefttofill.field.gender',
    'field.preferredContact': 'rb.progress.lefttofill.field.preferredContact',
    'field.phone': 'rb.progress.lefttofill.field.phone',
    'field.title': 'rb.progress.lefttofill.field.title',
    'field.specialization': 'rb.progress.lefttofill.field.specialization',
    'field.experience': 'rb.progress.lefttofill.field.experience',
    'field.language': 'rb.progress.lefttofill.field.language',
    'field.travelTime': 'rb.progress.lefttofill.field.travelTime',
    'field.citizenship': 'rb.progress.lefttofill.field.citizenship',
    'field.educationLevel': 'rb.progress.lefttofill.field.educationLevel',
    'field.postalAddress': 'rb.progress.lefttofill.field.postalAddress',
    'field.driverLicenseTypes': 'rb.progress.lefttofill.field.driverLicenseTypes',
    'field.hasVehicle': 'rb.progress.lefttofill.field.hasVehicle',
    'field.email': 'rb.progress.lefttofill.field.email',
    'field.skills': 'rb.progress.lefttofill.field.skills',
    'field.videoResume': 'rb.progress.lefttofill.field.videoResume',
    'field.primaryEducation': 'rb.progress.lefttofill.field.primaryEducation',
    'field.photo': 'rb.progress.lefttofill.field.photo',
    'field.attestationEducation': 'rb.progress.lefttofill.field.attestationEducation',
    'field.birthday': 'rb.progress.lefttofill.field.birthday',
    'field.personalSite': 'rb.progress.lefttofill.field.personalSite',
    'field.relocationArea': 'rb.progress.lefttofill.field.relocationArea',
    'field.salary': 'rb.progress.lefttofill.field.salary',
    'field.workTicket': 'rb.progress.lefttofill.field.workTicket',
    'field.portfolio': 'rb.progress.lefttofill.field.portfolio',
    'field.skills.personal': 'rb.progress.lefttofill.field.skills.personal',
    'field.keySkills': 'rb.progress.lefttofill.field.keySkills',
    'field.certificates': 'rb.progress.lefttofill.field.certificate',
    'field.additionalEducation': 'rb.progress.lefttofill.field.additionalEducation',
    'field.metro': 'rb.progress.lefttofill.field.metro',
    'field.recommendation': 'rb.progress.lefttofill.field.recommendation',
};

export default connect((state) => ({
    resume: state.resume,
}))(translation(ResumeProgress));
