import { PropsWithChildren, useState, FC } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { useSelector } from 'src/hooks/useSelector';
import { ResumeApi } from 'src/models/applicant/resume/api';
import {
    Payload,
    ResumeDeleteModalContext,
    DeleteContexResumeType,
} from 'src/models/applicant/resume/delete/resumeDeleteModal';

interface State {
    from: string;
    resume: DeleteContexResumeType | null;
    visible: boolean;
}

export const DeleteModalProvider: FC<PropsWithChildren> = ({ children }) => {
    const backUrl = useSelector(({ request }) => request.url);
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);

    const [state, setState] = useState<State>({ from: '', visible: false, resume: null });

    const openModal = ({ from, resume }: Payload) => {
        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'try_to_delete');
        setState({ from, resume, visible: true });
    };

    const closeModal = () => {
        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'cancel');
        setState({ from: '', visible: false, resume: null });
    };

    const deleteResume = () => {
        if (state.resume) {
            ResumeApi.delete(state.resume._attributes.hash, 'resume-delete', hhtmSource)
                .then(({ data }) => {
                    Analytics.sendEvent('applicant', 'resume-delete-complete-beacon', 'complete_delete', '', true);
                    window.location.assign(data.url);
                    setState({ from: '', visible: false, resume: null });
                })
                .catch(console.error);
        }
    };

    const hideResume = () => {
        if (state.resume) {
            ResumeApi.hide(state.resume._attributes.hash)
                .then(() => {
                    window.location.assign(backUrl);
                    setState({ from: '', visible: false, resume: null });
                })
                .catch(console.error);
        }
    };

    return (
        <ResumeDeleteModalContext.Provider
            value={{ visible: state.visible, resume: state.resume, closeModal, openModal, deleteResume, hideResume }}
        >
            {children}
        </ResumeDeleteModalContext.Provider>
    );
};
