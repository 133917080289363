import { TranslatedComponent } from '@hh.ru/front-static-app';

import { useHiringManagerCommentButton } from 'src/components/HiringManager/hooks/useHiringManagerCommentButton';
import ResumeComments from 'src/components/ResumeComments';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import HiringManagerComment from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

const TrlKeys = {
    title: 'resume.comments',
};

interface ResumeCommentsWrapperProps {
    inSidebar?: boolean;
}

const ResumeCommentsWrapper: TranslatedComponent<ResumeCommentsWrapperProps> = ({ trls, inSidebar = false }) => {
    const resumeHash = useSelector((state) => state.resume?.hash);
    const hiringManagerCommentButton = useHiringManagerCommentButton();
    const userId = useSelector((state) => Number(state.resume?.user));
    const { vacancyId, t: topicId } = useSelector((state) => state.router.location.query);

    const hiringManagerComment = { add: () => <HiringManagerComment /> };

    return (
        <ResumeBlock isTitleInside={inSidebar} title={trls[TrlKeys.title]} data-qa="resume-comments">
            <ResumeComments
                isResumePage
                user={userId}
                render={hiringManagerCommentButton ? hiringManagerComment : undefined}
                topicId={topicId}
                resumeHash={resumeHash}
                vacancyId={vacancyId}
            />
        </ResumeBlock>
    );
};

export default translation(ResumeCommentsWrapper);
