import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, Cell, CellText, FormLabel, Switch, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { format } from 'bloko/common/trl';

import { useTopLevelSite } from 'src/hooks/useSites';
import { selectResumeEditorOpenedVisibility } from 'src/models/applicant/resume/blocks/visibility/editor/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { HiddenFields } from 'src/models/resume/resumeCommon.types';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import { ItemCard } from 'src/pages/ResumeView/redesign/visibility/Editor/ItemCard';

const TrlKeys = {
    title: 'applicant.resume.visibility.editor.title',
    visibility: {
        all: 'applicant.resume.visibility.card.visibility.all',
        whitelist: 'applicant.resume.visibility.card.visibility.whitelist',
        blacklist: 'applicant.resume.visibility.card.visibility.blacklist',
        direct: 'applicant.resume.visibility.card.visibility.direct',
        no_one: 'applicant.resume.visibility.card.visibility.noOne',
    },
    hiddenFields: {
        title: 'applicant.resume.visibility.editor.hiddenFields.title',
        hint: 'applicant.resume.visibility.editor.hiddenFields.hint',
        description: 'applicant.resume.visibility.editor.hiddenFields.description',
        formLabel: 'applicant.resume.visibility.editor.hiddenFields.formLabel',
        fullname: 'applicant.resume.visibility.editor.hiddenFields.fullname',
        phones: 'applicant.resume.visibility.editor.hiddenFields.phones',
        email: 'applicant.resume.visibility.editor.hiddenFields.email',
        otherContacts: 'applicant.resume.visibility.editor.hiddenFields.otherContacts',
        experience: 'applicant.resume.visibility.editor.hiddenFields.experience',
    },
};

export const VisibilityEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedVisibility);

    const site = useTopLevelSite();

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const { value: accessType, onChange: onChangeAccessType } = useResumeEditorField('accessType', {});
    const { value: hiddenFields, onChange: onChangeHiddenFields } = useResumeEditorField('hiddenFields', {});

    const isFieldHidden = (field: HiddenFields) => hiddenFields?.includes(field);
    const toggleField = (field: HiddenFields) => {
        if (isFieldHidden(field)) {
            onChangeHiddenFields(hiddenFields.filter((item) => item !== field));
        } else {
            onChangeHiddenFields([...hiddenFields, field]);
        }
    };

    return (
        <EditorModal title={trls[TrlKeys.title]} visible={visible} onSave={handleSave} onClose={handleClose}>
            <VSpacingContainer default={12}>
                <ItemCard
                    checked={accessType === ResumeAccessType.Clients}
                    onChange={() => onChangeAccessType(ResumeAccessType.Clients)}
                >
                    {capitalized(format(trls[TrlKeys.visibility.all], { '{0}': site }))}
                </ItemCard>
                <ItemCard
                    checked={accessType === ResumeAccessType.WhiteList}
                    onChange={() => onChangeAccessType(ResumeAccessType.WhiteList)}
                >
                    {capitalized(trls[TrlKeys.visibility.whitelist])}
                </ItemCard>
                <ItemCard
                    checked={accessType === ResumeAccessType.BlackList}
                    onChange={() => onChangeAccessType(ResumeAccessType.BlackList)}
                >
                    {capitalized(trls[TrlKeys.visibility.blacklist])}
                </ItemCard>
                <ItemCard
                    checked={accessType === ResumeAccessType.Direct}
                    onChange={() => onChangeAccessType(ResumeAccessType.Direct)}
                >
                    {capitalized(trls[TrlKeys.visibility.direct])}
                </ItemCard>
                <ItemCard
                    checked={accessType === ResumeAccessType.NoOne}
                    onChange={() => onChangeAccessType(ResumeAccessType.NoOne)}
                >
                    {capitalized(trls[TrlKeys.visibility.no_one])}
                </ItemCard>
            </VSpacingContainer>
            <VSpacing default={24} />
            <Card padding={16} borderRadius={12} showBorder stretched>
                <Cell
                    align="top"
                    right={
                        <Switch
                            checked={!!hiddenFields?.length}
                            onClick={() => {
                                if (hiddenFields.length === 0) {
                                    onChangeHiddenFields([HiddenFields.NamesAndPhoto, HiddenFields.Phones]);
                                } else {
                                    onChangeHiddenFields([]);
                                }
                            }}
                        />
                    }
                >
                    <CellText>{trls[TrlKeys.hiddenFields.title]}</CellText>
                    <CellText type="description">{trls[TrlKeys.hiddenFields.description]}</CellText>
                </Cell>
            </Card>
            {!!hiddenFields?.length && (
                <>
                    <VSpacing default={16} />
                    <VSpacingContainer default={12}>
                        <FormLabel>{trls[TrlKeys.hiddenFields.hint]}</FormLabel>
                        <ItemCard
                            checked={isFieldHidden(HiddenFields.NamesAndPhoto)}
                            onChange={() => toggleField(HiddenFields.NamesAndPhoto)}
                            checkbox
                        >
                            {trls[TrlKeys.hiddenFields.fullname]}
                        </ItemCard>
                        <ItemCard
                            checked={isFieldHidden(HiddenFields.Phones)}
                            onChange={() => toggleField(HiddenFields.Phones)}
                            checkbox
                        >
                            {trls[TrlKeys.hiddenFields.phones]}
                        </ItemCard>
                        <ItemCard
                            checked={isFieldHidden(HiddenFields.Email)}
                            onChange={() => toggleField(HiddenFields.Email)}
                            checkbox
                        >
                            {trls[TrlKeys.hiddenFields.email]}
                        </ItemCard>
                        <ItemCard
                            checked={isFieldHidden(HiddenFields.OtherContacts)}
                            onChange={() => toggleField(HiddenFields.OtherContacts)}
                            checkbox
                        >
                            {trls[TrlKeys.hiddenFields.otherContacts]}
                        </ItemCard>
                        <ItemCard
                            checked={isFieldHidden(HiddenFields.Experience)}
                            onChange={() => toggleField(HiddenFields.Experience)}
                            checkbox
                        >
                            {trls[TrlKeys.hiddenFields.experience]}
                        </ItemCard>
                    </VSpacingContainer>
                </>
            )}
        </EditorModal>
    );
});
