import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import {
    selectResumeCondition,
    selectResumeEditorType,
    selectResumeEditorSaving,
    selectResumeEditorFields,
    selectResumeEditorPayload,
} from 'src/models/applicant/resume/editor/store/selectors';
import { setResumeEditorFormErrors } from 'src/models/applicant/resume/editor/store/slice';
import { ApplicantResumeEditorFields, UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import { closeResumeEditor } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { updateResume } from 'src/models/applicant/resume/editor/store/actions/updateResume';

export const submitResumeEditorData =
    () =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const saving = selectResumeEditorSaving(getState());
        if (saving) {
            return;
        }

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        if (!config.changed(getState())) {
            dispatch(closeResumeEditor());
            return;
        }

        const fields = selectResumeEditorFields(getState()) as Required<ApplicantResumeEditorFields>;
        const body = config.prepare(fields, getState()) as Required<UpdateBodyType>;

        void dispatch(saveResumeEditorData(body, true));
    };

export function saveResumeEditorData(body: Required<UpdateBodyType>, validateAsync: boolean) {
    return async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const type = selectResumeEditorType(getState());
        const { index: fieldIndex } = selectResumeEditorPayload(getState()) as PayloadType<{ index: number }>;
        const config = editorConfigMap[type];
        const conditions = selectResumeCondition(getState());

        let errors = config.validate(body, conditions);
        if (validateAsync && config.asyncValidate) {
            const { fields: updatedFields, errors: asyncErrors } = await config.asyncValidate(body, fieldIndex);
            if (asyncErrors) {
                errors = { ...errors, ...asyncErrors };
            }
            if (updatedFields) {
                dispatch(setApplicantResumeFields(updatedFields));
                body = { ...body, ...updatedFields };
            }
        }

        if (config.beforeSubmit) {
            config.beforeSubmit(getState(), errors);
        }

        if (!isEmpty(errors)) {
            dispatch(setResumeEditorFormErrors(errors));
            return;
        }

        void dispatch(updateResume(body));
    };
}
