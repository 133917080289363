import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, CheckableCard, Loader, VSpacingContainer, LoadedFile, Upload } from '@hh.ru/magritte-ui';
import { ExclamationTriangleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import CdnImg from 'src/components/CdnImg';
import { uploadResumePhotoArtifact } from 'src/models/applicant/resume/artifacts/actions';
import {
    selectArtivactsLoadedFiles,
    selectApplicantResumeArtivacts,
} from 'src/models/applicant/resume/artifacts/selectors';
import { WITHOUT_PHOTO_ID } from 'src/models/applicant/resume/blocks/position/edit/photo/constants';
import ArtifactPlaceholder from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ArtifactPlaceholder';

import styles from './styles.less';

const ARTIFACT_SIZE = '100%';

interface Props {
    selectedPhotoId: number;
    onChange: (id: number) => void;
}
const TrlKeys = {
    title: 'resume.editor.photo.upload.title',
    button: 'resume.editor.photo.upload.button',
    subTitle: 'resume.editor.photo.upload.subTitle',
    titleMobile: 'resume.editor.photo.upload.titleMobile',
    errors: {
        default: 'rb.edit.photo.uploaderror',
        network: 'rb.edit.photo.networkError',
        limit_reached: 'gallery.limitReached',
        image_too_large: 'gallery.imageTooLarge',
        too_many_images: 'resume.editor.photo.upload.error.tooManyImages',
        file_without_extension: 'rb.edit.photo.noExtension',
    },
};
const IMAGE_ACCEPT = { 'image/*': ['.png', '.jpeg', '.jpg'] };

const PhotoStep = translation<Props>(({ trls, selectedPhotoId, onChange }) => {
    const dispatch = useDispatch();
    const artifacts = useSelector(selectApplicantResumeArtivacts);
    const loadedFiles = useSelector(selectArtivactsLoadedFiles);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const artifactId = parseInt(event.target.value, 10);
        onChange(artifactId);
    };

    const handleDropAccepted = (acceptedFiles: LoadedFile[]) => {
        void dispatch(uploadResumePhotoArtifact(acceptedFiles[0]));
    };

    const handleFileDelete = () => {
        // Пока нельзя для аплоада убрать кнопку отмены запроса
        return undefined;
    };

    const renderFileInfo = (loadedFile: LoadedFile) => {
        const { error } = loadedFile;
        if (error) {
            if (Object.hasOwn(TrlKeys.errors, error)) {
                return trls[TrlKeys.errors[error as keyof typeof TrlKeys.errors]];
            }
            return trls[TrlKeys.errors.default];
        }
        return undefined;
    };

    return (
        <VSpacingContainer default={16}>
            <Upload
                size="medium"
                files={loadedFiles}
                noDragEventsBubbling
                accept={IMAGE_ACCEPT}
                onFileDelete={handleFileDelete}
                renderFileInfo={renderFileInfo}
                onDropAccepted={handleDropAccepted}
                trls={{
                    title: trls[TrlKeys.title],
                    titleMobile: trls[TrlKeys.titleMobile],
                    subTitle: trls[TrlKeys.subTitle],
                }}
                selectFileButton={
                    <Button mode="secondary" size="small" style="accent">
                        {trls[TrlKeys.button]}
                    </Button>
                }
            />
            <div className={styles.imageTable}>
                <CheckableCard
                    key="without"
                    type="radio"
                    value={WITHOUT_PHOTO_ID}
                    checked={selectedPhotoId === WITHOUT_PHOTO_ID}
                    onChange={handleChange}
                    borderRadius={16}
                >
                    <ArtifactPlaceholder size={ARTIFACT_SIZE} />
                </CheckableCard>
                {artifacts.map(({ big, id, state }) => {
                    return (
                        <CheckableCard
                            key={id}
                            type="radio"
                            value={id}
                            checked={selectedPhotoId === id}
                            onChange={handleChange}
                            borderRadius={16}
                        >
                            {state === 'new' && (
                                <div className={styles.artifact}>
                                    <Loader size={64} />
                                </div>
                            )}
                            {state === 'processed' && <CdnImg className={styles.artifact} path={big || ''} />}
                            {state === 'corrupted' && (
                                <div className={classnames(styles.artifact, styles.corrupted)}>
                                    <ExclamationTriangleOutlinedSize24 initial="secondary" />
                                </div>
                            )}
                        </CheckableCard>
                    );
                })}
            </div>
        </VSpacingContainer>
    );
});

export default PhotoStep;
