import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import {
    selectResumeEditorType,
    selectResumeEditorSaving,
    selectResumeEditorPayload,
} from 'src/models/applicant/resume/editor/store/selectors';
import { showResumeEditorConfirm } from 'src/models/applicant/resume/editor/store/slice';
import { UpdateBodyType } from 'src/models/applicant/resume/editor/types';

import { saveResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';

export const deleteFieldResumeEditor =
    (title: string) =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const saving = selectResumeEditorSaving(getState());
        if (saving) {
            return;
        }

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        if (config.delete) {
            dispatch(showResumeEditorConfirm({ type: 'block-item-delete', title }));
        }
    };

export const forceDeleteFieldResumeEditor =
    () =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const saving = selectResumeEditorSaving(getState());
        const payload = selectResumeEditorPayload(getState());
        if (saving) {
            return;
        }

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        if (config.delete) {
            const fields = config.delete(getState(), payload) as Required<UpdateBodyType>;
            void dispatch(saveResumeEditorData(fields, false));
        }
    };
