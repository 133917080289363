import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Text, Card, VSpacingContainer } from '@hh.ru/magritte-ui';

import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

import styles from './styles.less';

interface ResumeBlockProps {
    title?: ReactNode;
    isTitleInside?: boolean;
    border?: boolean;
    actions?: ReactNode;
    right?: ReactNode;
    buttons?: ReactNode;
}

const ResumeBlock: FC<Omit<ComponentPropsWithoutRef<'div'>, 'title'> & ResumeBlockProps> = ({
    title,
    isTitleInside = false,
    border = true,
    actions,
    right,
    buttons,
    children,
    ...otherProps
}) => (
    <div {...otherProps}>
        <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
            {!isTitleInside && title && (
                <div className={styles.header}>
                    <div className={styles.title}>
                        <Text typography="title-5-semibold">{title}</Text>
                        {actions && <div className={styles.actions}>{actions}</div>}
                    </div>
                    {right && <div className={styles.right}>{right}</div>}
                </div>
            )}
            <Card stretched showBorder={border} borderRadius={border ? 24 : 0} padding={border ? 12 : 0}>
                <VSpacingContainer default={border ? 12 : 24}>
                    <div className={classnames({ [styles.wrapper]: border })}>
                        <VSpacingContainer default={24}>
                            {isTitleInside && title && <Text typography="title-5-semibold">{title}</Text>}
                            <div>{children}</div>
                        </VSpacingContainer>
                    </div>
                    {buttons && <div className={styles.buttons}>{buttons}</div>}
                </VSpacingContainer>
            </Card>
        </VSpacingContainer>
    </div>
);

export default ResumeBlock;
