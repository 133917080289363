import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { Link, useBreakpoint } from '@hh.ru/magritte-ui';

import { selectApplicantResumeCertificates } from 'src/models/applicant/resume/blocks/certificates/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { ResumeCertificatesEditor } from 'src/pages/ResumeView/redesign/blocks/certificates/Editor';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';

const TrlKeys = {
    label: 'applicant.resume.certificates.label',
    add: 'applicant.resume.certificates.btn.mobile.add',
    listTitle: 'applicant.resume.certificates.mobile.list.title',
};

const ApplicantResumeCertificates = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const certificates = useSelector(selectApplicantResumeCertificates);

    const handleAdd = () => dispatch(openResumeEditorModal('certificate'));
    const handleEdit = (index: number) => dispatch(openResumeEditorModal('certificate', { index }));
    const items = useMemo(
        () =>
            certificates.map(({ id, title, achievementDate, url }) => ({
                id,
                title: url ? (
                    <Link href={url} target="_blank" style="neutral">
                        {title}
                    </Link>
                ) : (
                    title
                ),
                subtitle: new Date(achievementDate).getFullYear(),
                description: '',
            })),
        [certificates]
    );
    return (
        <>
            {!isEmpty(certificates) && (
                <>
                    {isMobile ? (
                        <MobileCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            addText={trls[TrlKeys.add]}
                            listTitle={trls[TrlKeys.listTitle]}
                            alignChevronRight="center"
                        />
                    ) : (
                        <DesktopCard onAdd={handleAdd} onEdit={handleEdit} items={items} label={trls[TrlKeys.label]} />
                    )}
                </>
            )}
            <ResumeCertificatesEditor />
        </>
    );
});

export default ApplicantResumeCertificates;
