import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { Modal, Loader, BottomSheet, NavigationBar, useBreakpoint, NavigationBarSize } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface Props {
    title?: string;
    visible: boolean;
    loading?: boolean;
    onClose: () => void;
    fullScreen?: boolean;
    description?: string;
    onAfterExit?: () => void;
    modalProps?: {
        size?: 'large' | 'medium' | 'small';
        footer?: ReactNode;
        actions?: ReactNode;
        options?: ReactNode;
    };
    bottomSheetProps?: {
        size?: NavigationBarSize;
        left?: ReactElement;
        right?: ReactElement;
        footer?: ReactElement;
        hideNav?: boolean;
        showDivider?: false | 'with-scroll';
        interceptClickHandlers?: boolean;
    };
}

const SmartModal: FC<PropsWithChildren<Props>> = ({
    title,
    visible,
    onClose,
    loading = false,
    children,
    fullScreen = false,
    onAfterExit,
    description,
    modalProps = {},
    bottomSheetProps = {},
}) => {
    const { isMobile } = useBreakpoint();

    const content = (
        <>
            {loading ? (
                <div className={styles.loader}>
                    <Loader size={64} />
                </div>
            ) : (
                children
            )}
        </>
    );

    if (isMobile) {
        return (
            <BottomSheet
                header={
                    bottomSheetProps.hideNav ? undefined : (
                        <NavigationBar
                            size={bottomSheetProps.size}
                            title={title}
                            left={bottomSheetProps.left}
                            right={bottomSheetProps.right}
                            subtitle={description}
                            showDivider={bottomSheetProps.showDivider}
                        />
                    )
                }
                footer={bottomSheetProps.footer}
                visible={visible}
                height={fullScreen ? 'full-screen' : undefined}
                onClose={onClose}
                showDivider={bottomSheetProps.showDivider}
                onAfterExit={onAfterExit}
                interceptClickHandlers={bottomSheetProps.interceptClickHandlers}
            >
                {loading ? (
                    <div className={styles.loader}>
                        <Loader size={64} />
                    </div>
                ) : (
                    children
                )}
            </BottomSheet>
        );
    }
    return (
        <Modal
            size={modalProps.size || 'medium'}
            title={title}
            footer={modalProps.footer}
            visible={visible}
            onClose={onClose}
            actions={modalProps.actions}
            titleSize="large"
            titleMaxLines={2}
            options={modalProps.options}
            titleDescription={description}
            closeByClickOutside={false}
            titleDescriptionStyle="secondary"
            onAfterExit={onAfterExit}
        >
            {fullScreen ? (
                <div
                    className={
                        modalProps.options
                            ? styles.fullScreenModalContentWrapperWithOptions
                            : styles.fullScreenModalContentWrapper
                    }
                >
                    {content}
                </div>
            ) : (
                content
            )}
        </Modal>
    );
};

export default SmartModal;
